import Home from "home/home";
import { Route, Switch, useLocation } from "react-router-dom";
import PoolDetails from "components/pool-details";
import BetDetailsPage from "components/bet-details-page";
import MyBets from "components/my-bets";
import Help from "components/help";
import Drawer from "components/drawer";
import "rsuite/dist/styles/rsuite-default.css";
import useDeviceDimensions from "hooks/useDeviceDimensions";
import SideBar from "components/sidebar";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { close } from "redux/slices/drawer-slice";
import SparketTutorial from "components/sparket-tutorial";
import { getGroupState, getGroupStyles, GroupSlug, setGroup } from "redux/slices/group-slice";
import { getUserState, setGanCredentials, setSuperAdmin, setUserId } from "redux/slices/user-slice";
import ReactGA from "react-ga";
import React, { useEffect } from "react";
import LegalModal from "components/legal-modal";
import OfflineAlert from "components/offline-alert";
import ConfirmResults from "components/confirm-results";
import ConfirmResultsPoolDetails from "components/confirm-results/pool-details";
import LeaderboardPage from "components/leaderboard-page";
import NotificationSettings from "components/notification-settings";
import { WagerWireIframe, WagerWireProvider } from "@wagerwire/embed/react";
import ErrorBoundary from "components/error-boundary/error-boundary";
import DebugPage from "components/debug-page/debug-page";
import {
  CURRENT_EXTERNAL_LOGIN_TOKEN,
  CURRENT_EXTERNAL_PLAYER_ID,
  CURRENT_GROUP,
  CURRENT_GROUP_SLUG,
  CURRENT_USER_ID,
  PENN_GROUP_SLUG,
  PENN_GROUP_SUBDOMAIN,
  STN_GROUP_SLUG,
  STN_GROUP_SUBDOMAIN
} from "utils/constants";
import UserGroupsResponse from "interfaces/user-groups-response";
import { getRequest } from "utils/httpClient";
import { getGroupBySlugPath, getInitializeGamePath, getUserPath } from "utils/backend-path-builders";
import { local as localStorage } from "utils/local-storage";
import useQueryParam from "utils/useQueryParams";
import { open as launchTutorial } from "redux/slices/sparket-tutorial-slice";

ReactGA.initialize("UA-143614690-1");

const App = () => {
  const excludeNavigationPaths = ["/privacy-policy", "/terms-of-service"];

  const excludeBackgroundColorPaths = [
    "/privacy-policy",
    "/terms-of-service",
    "/help",
    "/leaderboard",
    "/notification-settings",
  ];
  const group = useAppSelector(getGroupState);
  const user = useAppSelector(getUserState);

  const location = useLocation();
  const showNavigation = !excludeNavigationPaths.includes(location.pathname);
  const showBackgroundColor = !excludeBackgroundColorPaths.includes(
    location.pathname
  );

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);

  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceDimensions();

  useEffect(() => {
    dispatch(close());
  }, [isMobile]);

  useEffect(() => {
    if (user.loginToken && user.playerId && group.id) {
      fetch(process.env.REACT_APP_BACKEND_URL + getUserPath(), {
        headers: {
          "x-gan-user-id": user.playerId,
          "x-gan-login-token": user.loginToken,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((json) => dispatch(setSuperAdmin({ super_admin: json.super_admin })));
    }
  }, [user.loginToken, user.playerId, group.id]);

  useEffect(() => {
    const initializeGroupState = async () => {
      let groupSlug: GroupSlug = PENN_GROUP_SLUG;

      if (window.location.hostname.includes(PENN_GROUP_SUBDOMAIN)) {
        groupSlug = PENN_GROUP_SLUG;
      } else if (window.location.hostname.includes(STN_GROUP_SUBDOMAIN)) {
        groupSlug = STN_GROUP_SLUG;
      } else {
        throw new Error(`Unable to resolve group slug from the hostname: ${window.location.hostname}`);
      }

      const group: UserGroupsResponse = await getRequest(getGroupBySlugPath(groupSlug));
      localStorage.setItem(CURRENT_GROUP_SLUG, groupSlug);
      localStorage.setItem(CURRENT_GROUP, JSON.stringify(group));

      dispatch(setGroup(group));
    };

    if (!group.id) {
      initializeGroupState();
    }
  }, [group.id]);

  const queryParams = useQueryParam();

  useEffect(() => {
    const initGame = async () => {
      let playerId = localStorage.getItem(CURRENT_EXTERNAL_PLAYER_ID);
      let loginToken = localStorage.getItem(CURRENT_EXTERNAL_LOGIN_TOKEN);
      let userId = localStorage.getItem(CURRENT_USER_ID);

      const playerIdQueryParam = queryParams.get("playerId");
      const loginTokenQueryParam = queryParams.get("loginToken");

      if (playerIdQueryParam && loginTokenQueryParam) {
        console.log(`initialiaing game [playerId: ${playerId}, loginToken: ${loginToken}]`);
        playerId = playerIdQueryParam;
        loginToken = loginTokenQueryParam;

        localStorage.setItem(CURRENT_EXTERNAL_PLAYER_ID, playerId);
        localStorage.setItem(CURRENT_EXTERNAL_LOGIN_TOKEN, loginToken);

        const res = await fetch(
          process.env.REACT_APP_BACKEND_URL + getInitializeGamePath(),
          {
            method: "POST",
            headers: {
              "x-gan-user-id": playerId || "",
              "x-gan-login-token": loginToken || "",
            },
          }
        );

        const { token, is_new_user, user_id } = await res.json();

        // set new token
        if (token !== "") {
          loginToken = token;
        }

        if (is_new_user) {
          dispatch(launchTutorial());
        }

        userId = user_id;
        localStorage.setItem(CURRENT_USER_ID, userId ?? "");
      }

      dispatch(setUserId({ userId: userId }));
      dispatch(setGanCredentials({ playerId, loginToken }));
    };

    if (!user.loginToken && group.id) {
      initGame();
    }
  }, [user.loginToken, group.id]);

  const conditionalStyles = isMobile
    ? {}
    : {
      alignSelf: "flex-end",
      height: "100vh",
      overflow: "scroll",
      width: "100%",
    };

  const primaryColor = useAppSelector(getGroupStyles).primary_color;

  const backgoundStyles = showBackgroundColor
    ? {
      backgroundImage: `linear-gradient(to right, ${primaryColor} , black)`,
    }
    : { backgroundColor: "#F1F2F2" };

  const wagerWireEnabled = group.settings.wager_wire;

  return (
    <ErrorBoundary isAdmin={user.super_admin}>
      <WagerWireProvider
        userId={user.id}
        embedOrigin={process.env.REACT_APP_WAGER_WIRE_EMBED_ORIGIN || ""}
      >
        <div style={{ display: isMobile ? "initial" : "flex" }}>
          {showNavigation && <>{isMobile ? <Drawer /> : <SideBar />}</>}
          <div
            id="sparket-scrollable-container"
            style={{
              minHeight: "100vh",
              ...backgoundStyles,
              ...conditionalStyles,
            }}
          >
            <Switch>
              <Route exact path="/mybets" component={MyBets} />
              <Route exact path="/bets/:id" component={BetDetailsPage} />
              <Route exact path="/pools/:id" component={PoolDetails} />
              <Route exact path="/help" component={Help} />
              <Route exact path="/leaderboard" component={LeaderboardPage} />

              <Route exact path="/" component={Home} />
              <Route exact path="/g/:groupSlug" component={Home} />
              <Route exact path="/confirm-results" component={ConfirmResults} />
              <Route
                exact
                path="/notification-settings"
                component={NotificationSettings}
              />
              <Route
                exact
                path="/pools/:id/confirm"
                component={ConfirmResultsPoolDetails}
              />
              <Route exact path="/launchGame/:groupSlug" component={Home} />
              {user.super_admin && <Route exact path="/debug" component={DebugPage} />}
            </Switch>
            <SparketTutorial />
            <LegalModal />
            <OfflineAlert />
          </div>
        </div>
        {wagerWireEnabled && <WagerWireIframe />}
      </WagerWireProvider>
    </ErrorBoundary>
  );
};

export default App;
