import Headroom from "react-headroom";
import Header from "components/header";
import Leaderboard from "components/leaderboard";
import { Mixpanel } from "hooks/mixPanel";
import { useEffect } from "react";

const LeaderboardPage = () => {
  useEffect(() => {
    Mixpanel.track("Leaderboard rendered", {});
  });
  return (
    <>
      <Headroom>
        <Header />
      </Headroom>
      <Leaderboard />
    </>
  );
};

export default LeaderboardPage;
