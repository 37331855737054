import listStyles from "assets/shared-stylesheets/sparket-list-item.module.css";
import styles from "./styles.module.scss";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import BlockyBlockyMediumText from "components/custom-texts/blocky-medium-text";
import { Entry } from "interfaces/entry";
import { Button, Spinner } from "react-bootstrap";

interface Props {
  entry: Entry;
  onConfirm: Function;
  isLoading: boolean;
  height?: number;
}

const Form = ({ entry, onConfirm, isLoading, height }: Props) => {
  return (
    <div
      style={{ height }}
      className={`${listStyles.itemContainer} ${styles.formContainer}`}
    >
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            maxWidth: 300,
          }}
        >
          <BlockyBlockyMediumText>
            Confirm {entry.contestant_name} as winner?
          </BlockyBlockyMediumText>
          <Button
            bsPrefix="foo"
            className={`${styles.btn} ${styles.btnPrimary}`}
            onClick={() => onConfirm(entry)}
          >
            <BlockyHeavyText>Confirm</BlockyHeavyText>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Form;
