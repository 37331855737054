export const POOL_STATUS = {
  PENDING: 1,
  OPEN: 2,
  LIVE: 3,
  CALCULATING: 4,
  SETTLED: 5,
  CANCELLED: -1,
};

export enum POOL_STATUS_PARAM {
  LIVE = "live",
  OPEN = "open",
}

export const BET_STATUS = {
  CREATED: 1,
  CONFIRMED: 2,
  ACTIVE: 3,
  CANCELLED: 4,
  SOLD: 5,
  WIN: 6,
  LOSS: 7,
};

export const sparketGold = "var(--sparket-gold)";
export const sparketLightGray = "var(--sparket-light-gray)";
export const sparketDarkRed = "var(--sparket-dark-red)";
export const sparketBlack = "#111920";
export const sparketGreen = "#66c085";
export const sparketDarkGreen = "var(--sparket-dark-green";
export const sparketDarkOrange = "var(--sparket-dark-orange";
export const fiftyYardImgUrl =
  "https://trello-attachments.s3.amazonaws.com/5f3cbc1a9909f162483bcbac/5fdd2990784c2c170712917b/249018ffac7c1867cc04274783a75a00/lights.jpg";
export const logoImgUrl =
  "https://trello-attachments.s3.amazonaws.com/5f3cbc1a9909f162483bcbac/5fdd2990784c2c170712917b/7bcac879b4deeef12bb3605730a89517/socialbetwork.png";
export const HAS_SEEN_SPARKET_TUTORIAL = "hasSeenSparketTutorial";
export const CURRENT_GROUP_ID = "currentGroupId";
export const CURRENT_USER_ID = "currentUserId";
export const CURRENT_GROUP = "currentGroup";
export const CURRENT_GROUP_SLUG = "currentGroupSlug";
export const CURRENT_EXTERNAL_PLAYER_ID = "currentExternalPlayerId";
export const CURRENT_EXTERNAL_LOGIN_TOKEN = "currentExternalLoginToken";

export const PENN_GROUP_SLUG = "pennplaylive";
export const PENN_GROUP_SUBDOMAIN = "pennplaylive";

export const STN_GROUP_SLUG = "sparketsportsbook";
export const STN_GROUP_SUBDOMAIN = "gan";

export const STN_GROUP_API_PREFIX = "gan";
export const PENN_GROUP_API_PREFIX = "penn";

export const APPROVED = "approved";
export const PENDING = "pending";
export const PENDING_IDENTITY_VERIFICATION = "pending_identity_verification";
export const NOT = "not";
export const SUCCEEDED = "succeeded";
export const LOADING = "loading";
export const FAILED = "failed";
export const IDLE = "idle";
export const WAITING = "WAITING";
export const ALLOWED = "ALLOWED";
export const DENIED = "DENIED";
export const XPOINT_NOT_FOUND = "XPOINT_NOT_FOUND";
export const SKIP = "SKIP";

export const SMS_MFA = "SMS_MFA";
export const SMS = "SMS";
export const NOMFA = "NOMFA";

export const GRANTED = "granted";

export enum VERIFICACTION_STATUS {
  VERIFIED = "verified",
  UNVERIFIED = "unverified",
  PENDING = "pending",
  ERROR = "error",
}

export const ID_VERIFICATION_REDIRECT_PATH = "/verification/confirmation";
export const ID_VERIFICATION_REQUEST_PATH = "/id-verification-request";
export const USER_SETTINGS_LOCAL_STORAGE_ITEM_NAME = "ganIframeAppUserSettings";
