import React, { useEffect, useState } from "react";
import EntryList from "./entries/entry-list";
import { Entry } from "interfaces/entry";
import { Bet } from "interfaces/bet";
import PoolSummary from "./pool-summary";
import { PoolDetailsResponse } from "interfaces/pool-details-response";
import { getRequest } from "utils/httpClient";
import Header from "components/header";
import Headroom from "react-headroom";
import LoadingSpinner from "components/loading-spinner.tsx";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import SuccessfulBetModal from "./successful-bet-modal";
import { getPoolDetailsPath, } from "utils/backend-path-builders";
import { BetTypeName, betTypeWin } from "interfaces/bet-type-name";

const PoolDetails = (props: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [entries, setEntries] = useState<Entry[]>([]);
  const [contestants, setContestants] = useState([]);
  const [pool, setPool] = useState<PoolDetailsResponse>(
    {} as PoolDetailsResponse
  );
  const group = useAppSelector(getGroupState);
  const [successfulBet, setSuccessfulBet] = useState<Bet | undefined>();
  const [currentPoolType, setCurrentPoolType] = useState<BetTypeName>(betTypeWin);

  const onSuccessfulBetPlaced = (bet: Bet) => {
    setSuccessfulBet(bet);
    setLoading(true);
  };

  useEffect(() => {
    const id: number = props.match.params.id;
    const path = getPoolDetailsPath(group.id, id.toString());

    getRequest(path).then((data) => {
      setEntries(data.entries);
      setContestants(data.contestants);
      setPool(data);
      setLoading(false);
    });
  }, [props.match.params.id, loading]);


  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Headroom>
        <Header />
        <PoolSummary pool={pool} betType={currentPoolType} />
      </Headroom>

      {contestants && contestants.length >= 1 ||
      entries && entries.length >= 1 ? <EntryList
          pool={pool}
          setCurrentPoolType={setCurrentPoolType}
          onSuccessfulBetPlaced={onSuccessfulBetPlaced}
        /> :
        <BlockyHeavyText
          style={{
            color: "white",
            display: "flex",
            justifyContent: "center",
            marginTop: 15,
          }}
        >
          This pool has no contestants
        </BlockyHeavyText>
      }
      {successfulBet && <SuccessfulBetModal
        bet={successfulBet}
        close={() => setSuccessfulBet(undefined)}
      />
      }
    </>
  );
};

export default PoolDetails;
