import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const header = document.getElementsByClassName("headroom-wrapper")[0];
    header?.scrollIntoView();
  }, [pathname]);

  return null;
}
