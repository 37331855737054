import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import Header from "components/header";
import LoadingSpinner from "components/loading-spinner.tsx";
import { Bet } from "interfaces/bet";
import { useEffect, useState } from "react";
import Headroom from "react-headroom";
import MyBetItem from "./my-bet-item";
import styles from "./styles.module.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getMyBetsPath } from "utils/backend-path-builders";
import Modal from "./modal";
import { getUserState, setGanCredentials, setUserId } from "redux/slices/user-slice";
import { local as localStorage } from "utils/local-storage";
import { CURRENT_GROUP, CURRENT_EXTERNAL_LOGIN_TOKEN, CURRENT_EXTERNAL_PLAYER_ID, CURRENT_USER_ID, } from "utils/constants";
import { getGroupState, setGroup } from "redux/slices/group-slice";

const MyBets = () => {
  const [bets, setBets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBet, setSelectedBet] = useState<Bet | undefined>();
  const groupState = useAppSelector(getGroupState);
  const user = useAppSelector(getUserState);
  const dispatch = useAppDispatch();

  const getBetListData = () => {
    let usePlayerId = user.playerId || "";
    let loginToken = user.loginToken || "";
    if (usePlayerId === "" || loginToken === "") {
      usePlayerId = localStorage.getItem(CURRENT_EXTERNAL_PLAYER_ID) || "";
      loginToken = localStorage.getItem(CURRENT_EXTERNAL_LOGIN_TOKEN) || "";
      let group = localStorage.getItem(CURRENT_GROUP);
      dispatch(setUserId({userId: localStorage.getItem(CURRENT_USER_ID)}));
      dispatch(setGanCredentials({ playerId: usePlayerId, loginToken }));
      dispatch(setGroup(JSON.parse(group || "")));
    }
    fetch(process.env.REACT_APP_BACKEND_URL + getMyBetsPath(), {
      headers: {
        "x-gan-user-id": usePlayerId,
        "x-gan-login-token": loginToken,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.length >= 1) {
          const sortedBets = data.sort(
            (a: Bet, b: Bet) => b.created - a.created
          );
          setBets(sortedBets);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    getBetListData();
  }, []);

  return (
    <>
      <Headroom>
        <Header />
      </Headroom>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {bets !== null && bets.length >= 1 ? (
            <>
              <div className={styles.titleRow}>
                <BlockyHeavyText
                  customStyles={{ fontSize: 22, marginRight: 10 }}
                >
                  My Bets
                </BlockyHeavyText>
              </div>
              {bets.map((bet, index) => {
                return (
                  <MyBetItem
                    setSelectedBet={setSelectedBet}
                    key={index}
                    bet={bet}
                  />
                );
              })}
            </>
          ) : (
            <div className={styles.noBetsTextContainer}>
              <BlockyHeavyText>You haven't made any bets yet</BlockyHeavyText>
            </div>
          )}
        </>
      )}
      <Modal bet={selectedBet} hide={() => setSelectedBet(undefined)} />
    </>
  );
};

export default MyBets;
