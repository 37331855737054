import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface LegalModalState {
  termsOfServiceOpen: boolean;
  privacyPolicyOpen: boolean;
  contestTermsOpen: boolean;
}

const initialState: LegalModalState = {
  termsOfServiceOpen: false,
  privacyPolicyOpen: false,
  contestTermsOpen: false,
};

const slice = createSlice({
  name: "legalModal",
  initialState,
  reducers: {
    openTermsOfService: (state) => {
      state.termsOfServiceOpen = true;
    },
    openPrivacyPolicy: (state) => {
      state.privacyPolicyOpen = true;
    },
    openContestTerms: (state) => {
      state.contestTermsOpen = true;
    },
    close: (state) => {
      state.termsOfServiceOpen = false;
      state.privacyPolicyOpen = false;
      state.contestTermsOpen = false;
    },
  },
});

export const {
  openContestTerms,
  openTermsOfService,
  openPrivacyPolicy,
  close,
} = slice.actions;

export const getLegalModalState = (state: RootState) => {
  return state.legalModal;
};

export default slice.reducer;
