import OpenSansText from "components/custom-texts/open-sans-text";
import betDetailLineStyles from "./bet-detail-line-styles.module.css";

const dots =
  " . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
interface BetDetailLineProps {
  label: string;
  value: string;
}
const BetDetailLine = ({ label, value }: BetDetailLineProps) => {
  return (
    <div className={betDetailLineStyles.container}>
      <div>
        <OpenSansText
          className={betDetailLineStyles.label}
          customStyles={{
            fontSize: 15,
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {label}{" "}
        </OpenSansText>
      </div>
      <div style={{ overflow: "hidden" }}>
        <OpenSansText customStyles={{ whiteSpace: "nowrap", opacity: 0.3 }}>
          {dots}
        </OpenSansText>
      </div>

      <div>
        <OpenSansText
          className={betDetailLineStyles.value}
          customStyles={{ fontSize: 15, whiteSpace: "nowrap" }}
        >
          {value}
        </OpenSansText>
      </div>
    </div>
  );
};

export default BetDetailLine;
