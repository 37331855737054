interface Props {
  children: string | any;
  style?: object;
  customStyles?: object;
  className?: string;
}
const OpenSansText = (props: Props) => {
  return (
    <div
      className={props.className}
      style={{
        fontFamily: "Open Sans",
        ...props.style,
        ...props.customStyles,
      }}
    >
      {props.children}
    </div>
  );
};

export default OpenSansText;
