import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { Modal } from "react-bootstrap";

interface RulesModalProps {
  rules: string;
  showRulesModal: boolean;
  setShowRulesModal: CallableFunction;
}
const RulesModal = (props: RulesModalProps) => {
  return (
    <Modal
      show={props.showRulesModal}
      onHide={() => {
        props.setShowRulesModal(false);
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <BlockyBoldText>Rules</BlockyBoldText>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BlockyBoldText>{props.rules}</BlockyBoldText>
      </Modal.Body>
    </Modal>
  );
};

export default RulesModal;
