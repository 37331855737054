import { Carousel } from "react-bootstrap";
import { CaretLeftFill, CaretRightFill } from "react-bootstrap-icons";
import PageOneContent from "./page-1-content";
import PageTwoContent from "./page-2-content";
import PageThreeContent from "./page-3-content";
import PageFourContent from "./page-4-content";

const TutorialCarousel = () => {
  return (
    <Carousel
      prevIcon={<CaretLeftFill style={{ color: "black" }} />}
      nextIcon={<CaretRightFill style={{ color: "black" }} />}
      indicators={false}
      wrap={false}
      interval={null}
    >
      <Carousel.Item>
        <PageOneContent />
      </Carousel.Item>
      <Carousel.Item>
        <PageTwoContent />
      </Carousel.Item>
      <Carousel.Item>
        <PageThreeContent />
      </Carousel.Item>
      <Carousel.Item>
        <PageFourContent />
      </Carousel.Item>
    </Carousel>
  );
};

export default TutorialCarousel;
