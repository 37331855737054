import Spinner from "react-bootstrap/Spinner";
import { useAppSelector } from "redux/hooks";
import { getGroupStyles } from "redux/slices/group-slice";

const LoadingSpinner = () => {
  const primaryColor = useAppSelector(getGroupStyles).primary_color;
  return (
    <div
      style={{
        color: primaryColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Spinner animation="border" />
    </div>
  );
};

export default LoadingSpinner;
