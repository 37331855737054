interface Props {
  children: string | any;
  style?: object;
  customStyles?: object;
  className?: string;
}
const BlockyBoldText = (props: Props) => {
  return (
    <div
      className={props.className}
      style={{
        fontFamily: "Chakra Petch",
        fontWeight: 700,
        ...props.customStyles,
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

export default BlockyBoldText;
