import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { Entry } from "interfaces/entry";
import { createRef, useEffect, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useAppSelector } from "redux/hooks";
import { getGroupCurrencySymbol, getGroupSecondaryColorOrDefault, getGroupState, } from "redux/slices/group-slice";
import { formatAsCurrency, formattedCurrencyToNumber, } from "utils/formatter-utils/currency-formatter";
import styles from "components/pool-details/bet-modal/bet-modal-content/styles.module.css";
import ChangeHighlight from "react-change-highlight";
import OpenSansText from "components/custom-texts/open-sans-text";
import { calculateOdds } from "utils/oddsCalculator";
import { PoolDetailsResponse } from "interfaces/pool-details-response";
import confirmBet from "../confirm-bet";
import { Bet } from "interfaces/bet";
import { getUserState } from "redux/slices/user-slice";
import { toast } from "react-toastify";

interface Props {
  entry: Entry;
  pool: PoolDetailsResponse;
  onSuccessfulBetPlaced: (bet: Bet) => void;
  wallet: { formattedBalance: string; balance: number };
}

const BetForm = ({ entry, pool, onSuccessfulBetPlaced, wallet }: Props) => {
  const formattedBalance = wallet.formattedBalance;
  const balance = wallet.balance;

  const [betAmount, setBetAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);

  const group = useAppSelector(getGroupState);
  const user = useAppSelector(getUserState);
  const [errorMessage, setErrorMessage] = useState<string>();
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current, entry]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  const currencySymbol = useAppSelector(getGroupCurrencySymbol);
  const contestantIds = entry.contestant_id
    .split(";")
    .filter((id) => id.length > 0);
  const selectedContestants = contestantIds.map((id) => {
    const contestant = pool.contestants.find((c) => c.id === id);
    return contestant;
  });

  const numberOfSelections = selectedContestants.length;
  const poolTotalKey = ["total_win", "total_exacta", "total_trifecta"][
  numberOfSelections - 1
    ];
  const poolTotal = (pool as any)[poolTotalKey];

  const odds = calculateOdds({
    poolTotal,
    entryTotal: entry.bet_total || entry.bets_sum || 0,
    betAmount: formattedCurrencyToNumber(betAmount) || 0,
    fee: pool.fee
  });

  const projectedPayout =
    formattedCurrencyToNumber(betAmount || "0") * (odds || 0);

  const formattedProjectedPayout = formatAsCurrency(projectedPayout, group.uri);
  const winType = poolTotalKey === "total_win";

  const [amountInputIsFocused, setAmountInputIsFocused] = useState(false);

  const amountInputBaseStyle = {
    borderRadius: 5,
    height: 40,
    color: "black",
    width: "95%"
  };

  const amountInputFocusedStyle = amountInputIsFocused
    ? {
      border: `3px solid ${secondaryColor}`,
      outlineWidth: "3px",
      outlineColor: secondaryColor
    }
    : {};

  const amountInputStyle = { ...amountInputBaseStyle, ...amountInputFocusedStyle };

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        width: "-webkit-fill-available",
        background: "black",
        color: "white",
        padding: 10,
        fontSize: 14
      }}
    >
      {/* top panel */}
      <div style={{ width: "100%", display: "flex", padding: 2 }}>
        {/* left panel */}
        <div style={{ flex: 0.5 }}>
          <BlockyBoldText>
            Available Balance:{" "}
            <span style={{ color: secondaryColor }}>{formattedBalance}</span>
          </BlockyBoldText>
        </div>
        {/* right panel */}
        <div style={{ flex: 0.5 }}>
          <BlockyBoldText>
            Selected:{" "}
            {!winType &&
              selectedContestants.map((selection, index) => {
                return (
                  <span key={index} style={{ color: secondaryColor }}>
                    {selection?.name} ({index + 1});{" "}
                  </span>
                );
              })}
            {winType && (
              <span style={{ color: secondaryColor }}>
                {entry.contestant_name}
              </span>
            )}
          </BlockyBoldText>
        </div>
      </div>

      {/* middle panel */}
      <div style={{ display: "flex", padding: 3 }}>
        {/* left */}
        <div style={{ flex: 0.5 }}>
          <NumberFormat
            id="input-BetModalContent-betAmountInput"
            getInputRef={(e: HTMLInputElement) => inputRef.current = e}
            onValueChange={(value) => {
              if (!value.floatValue) {
                setBetAmount("$0.00");
              }

              if (formattedCurrencyToNumber(value.formattedValue) <= balance) {
                setBetAmount(value.formattedValue);
              }
            }}
            prefix={currencySymbol}
            decimalScale={2}
            thousandSeparator
            inputMode="decimal"
            fixedDecimalScale
            allowNegative={false}
            disabled={loading}
            isAllowed={(value) => {
              if (value && value.floatValue) {
                return value && value?.floatValue <= balance;
              }
              return true;
            }}
            onFocus={() => setAmountInputIsFocused(true)}
            onBlur={() => setAmountInputIsFocused(false)}
            placeholder="Enter Amount"
            style={amountInputStyle}
          />

          <div style={{ display: "flex" }}>
            <BlockyBoldText style={{ marginTop: 10, display: "flex" }}>
              Odds: &nbsp;
              <ChangeHighlight
                hideAfter={1000}
                highlightClassName={styles.highlight}
              >
                <div
                  ref={createRef()}
                  style={{ color: secondaryColor }}
                >{`${odds.toFixed(2)}X`}</div>
              </ChangeHighlight>
            </BlockyBoldText>
          </div>
        </div>
        {/* right */}
        <div style={{ width: "100%", flex: 0.5 }}>
          <Button
            style={{
              width: "100%",
              height: 41,
              backgroundColor: secondaryColor,
              color: "black"
            }}
            disabled={loading || !betAmount || betAmount === "$0.00"}
            onClick={() => {
              confirmBet({
                betAmount,
                groupUri: group.uri,
                poolEntryId: entry.id || entry.pool_entry_id,
                pool_id: pool.id,
                setLoading,
                setErrorMessage,
                onSuccessfulBetPlaced: (bet: Bet) => {
                  onSuccessfulBetPlaced(bet);
                },
                currency: group.currency_code,
                playerId: user.playerId,
                loginToken: user.loginToken
              });
            }}
          >
            {loading && (
              <Spinner
                animation="border"
                size="sm"
                style={{ marginRight: 10 }}
              />
            )}
            Confirm
          </Button>

          <div style={{ marginTop: 7 }}>
            <div style={{ display: "flex" }}>
              <BlockyBoldText>Projected Payout: &nbsp;</BlockyBoldText>
              <BlockyBoldText className={styles.text}>
                <ChangeHighlight
                  hideAfter={1000}
                  highlightClassName={styles.highlight}
                >
                  <span ref={createRef()} style={{ color: secondaryColor }}>
                    {formattedProjectedPayout}
                  </span>
                </ChangeHighlight>
              </BlockyBoldText>
            </div>
          </div>
        </div>
      </div>


      <OpenSansText
        className={styles.disclaimerText}
        style={{ fontSize: 10, textAlign: "center", color: "gray" }}
      >
        By Clicking 'Confirm' the amount indicated will be credited from your
        balance. Projected payouts are subject to change based on subsequent
        activity in the pool.
      </OpenSansText>
    </div>
  );
};

export default BetForm;
