import { useEffect, useState } from "react";
import Select from "react-select";
import { getRequest } from "utils/httpClient";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import PoolItem from "components/pool-item/pool-item";
import styles from "assets/shared-stylesheets/sparket-list-item.module.css";
import Header from "components/header";
import LoadingSpinner from "components/loading-spinner.tsx";
import Headroom from "react-headroom";
import { Pool } from "interfaces/pool";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import "react-bootstrap-typeahead/css/Typeahead.css";
import useDeviceDimensions from "hooks/useDeviceDimensions";
import SetupMixpanel from "app-setup/mixpanel-init";
import { getPoolsPath, } from "utils/backend-path-builders";
import { POOL_STATUS_PARAM, } from "utils/constants";
import { getUserState } from "redux/slices/user-slice";
import { SparketsBanner, ViewMarketplaceButton, } from "@wagerwire/embed/react";
import {
  clearHomePageEventIdFilter,
  getUserGroupSettingsByGroupId,
  setHomePageEventIdFilter
} from "redux/slices/user-settings-slice";

const Home = (props: any) => {

  const [poolsLoaded, setPoolsLoaded] = useState(false);
  const [loadingPools, setLoadingPools] = useState(true);
  const [pools, setPools] = useState<Pool[]>([]);

  const [uniqueEvents, setUniqueEvents] = useState<EventIdAndName[]>([]);

  const [eventFilter, setEventFilter] = useState<EventIdAndName>();
  const [filteredPools, setFilteredPools] = useState<Pool[]>([]);

  const group = useAppSelector(getGroupState);
  const user = useAppSelector(getUserState);

  const userGroupSettings = useAppSelector(state => getUserGroupSettingsByGroupId(state, group.id));

  SetupMixpanel();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const syncUserSettingsAndEventFilter = () => {
      if (!poolsLoaded) {
        return;
      }

      const eventFilter = uniqueEvents.find(event => event.id === userGroupSettings?.homePagePoolsEventIdFilter);

      if (!eventFilter) {
        dispatch(clearHomePageEventIdFilter({ groupId: group.id }));
        setEventFilter(undefined);
      } else {
        setEventFilter(eventFilter);
      }
    };

    syncUserSettingsAndEventFilter();
  }, [userGroupSettings?.homePagePoolsEventIdFilter, uniqueEvents, poolsLoaded]);

  useEffect(() => {
    const filterPools = () => {
      if (!eventFilter) {
        setFilteredPools(pools);
      } else {
        setFilteredPools(pools.filter((pool) => pool.event_id === eventFilter.id));
      }
    };

    filterPools();
  }, [pools, eventFilter]);

  useEffect(() => {
    if (group.id === "") {
      setPools([]);
      return;
    }
    const path = getPoolsPath(group.id);
    getRequest(path, {
      queryParams: {
        status: POOL_STATUS_PARAM.OPEN,
        pg: 1,
        page_size: 10000,
        sortBy: "weight:desc,close:asc"
      }
    })
      .then((poolsResponse: Pool[]) => {

        setPools(poolsResponse);

        const eventIdAndNameByEventId = new Map(
          poolsResponse
            .filter(p => p.event_name && p.event_id)
            .map(p => [p.event_id, { id: p.event_id, name: p.event_name } as EventIdAndName])
        );

        const uniqueEvents = Array.from(eventIdAndNameByEventId.values());
        setUniqueEvents(uniqueEvents);
      })
      .finally(() => {
        setPoolsLoaded(true);
        setLoadingPools(false);
      });
  }, [group.id]);

  const { isMobile } = useDeviceDimensions();
  const eventFilterWidth = isMobile ? "100%" : "50%";

  if (loadingPools) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <Headroom>
          <div>
            <Header />
          </div>
        </Headroom>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 20,
            paddingRight: 20,
            flexWrap: "wrap-reverse",
          }}
        >
          {group.settings.wager_wire && (
            <ViewMarketplaceButton
              groupColor={group.settings.primary_color}
              style={{ width: "100%", padding: 0 }}
              groupId={group.id}
            >
              <SparketsBanner groupColor={group.settings.primary_color} />
            </ViewMarketplaceButton>
          )}
          {pools.length > 0 && (
            <div
              style={{
                width: eventFilterWidth,
                paddingTop: 10,
                paddingBottom: 5,
              }}
            >
              <Select
                isClearable
                placeholder={"Filter by event"}
                options={Array.from(uniqueEvents).map((event: EventIdAndName) => ({
                  value: event.id,
                  label: event.name
                }))}
                value={eventFilter ? { value: eventFilter?.id, label: eventFilter?.name } : null}
                onChange={(selectedEvent) => {
                  if (selectedEvent == null) {
                    dispatch(clearHomePageEventIdFilter({ groupId: group.id }));
                  } else {
                    dispatch(setHomePageEventIdFilter({ groupId: group.id, eventId: selectedEvent.value }));
                  }
                }}
              />
            </div>
          )}
        </div>
        {filteredPools.length > 0 ? (
          <>
            {filteredPools.map((pool) => {
              return (
                <PoolItem
                  key={pool.id}
                  styles={styles}
                  pool={pool}
                  link={`/pools/${pool.id}`}
                  showCountDownClock={true} />
              );
            })}
          </>
        ) : (
          <BlockyHeavyText
            customStyles={{
              display: "flex",
              justifyContent: "center",
              color: "white",
            }}
          >
            No pools are currently open. Please check back later.
          </BlockyHeavyText>
        )}
      </>
    );
  }
};

export default Home;

interface EventIdAndName {
  id: string;
  name: string;
}