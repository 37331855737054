import { Table } from "react-bootstrap";
import { LeaderboardEntry } from "interfaces/leaderboard/leaderboard-entry";
import UserRow from "./user-row";
import HeaderRow from "./header-row";
import styles from "./styles.module.css";
import fullScreenStyles from "./full-screen-styles.module.css";

interface Props {
  leaderboardUser: LeaderboardEntry;
  leaderboardList: LeaderboardEntry[];
  fullScreen: boolean;
}

const LeaderTable = ({
  leaderboardUser,
  leaderboardList,
  fullScreen,
}: Props) => {
  const styleSheet = fullScreen ? fullScreenStyles : styles;
  return (
    <Table striped>
      <HeaderRow />
      <tbody>
        {leaderboardUser && !fullScreen && (
          <UserRow
            user={leaderboardUser}
            style={{ backgroundColor: "var(--sparket-black)", color: "white" }}
            classNames={styleSheet}
          />
        )}
        {leaderboardList.map((leaderboardEntry: LeaderboardEntry) => {
          return (
            <UserRow
              key={leaderboardEntry.user_id}
              user={leaderboardEntry}
              classNames={styleSheet}
            />
          );
        })}
      </tbody>
    </Table>
  );
};
export default LeaderTable;
