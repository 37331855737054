import { createSlice } from "@reduxjs/toolkit";
import UsersResponse, { IDENTITY_VERIFICATION_STATUS, } from "interfaces/users-response";
import { IDLE } from "utils/constants";
import { RootState } from "../store";

interface UserState extends UsersResponse {
  fetchUserState: "idle" | "loading" | "succeeded" | "failed";
  playerId: string;
  loginToken: string;
}

export const initialState: UserState = {
  avatar: "",
  bio: "",
  created_at: "",
  id: "",
  last_login: "",
  last_seen: "",
  password_last_changed: "",
  status: "",
  super_admin: false,
  user_name: "",
  validation_status: IDENTITY_VERIFICATION_STATUS.NOT_STARTED,
  fetchUserState: IDLE,
  validation_request_id: "",
  notifications: { email: {} },
  playerId: "",
  loginToken: "",
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setEmailConfig: (state, action) => {
      state.notifications = action.payload.notifications;
    },
    setUserId: (state, action) => {
      state.id = action.payload.userId;
    },
    setGanCredentials: (state, action) => {
      state.playerId = action.payload.playerId;
      state.loginToken = action.payload.loginToken;
    },
    setSuperAdmin(state, action) {
      state.super_admin = action.payload.super_admin;
    },
  },
});

export const getUserState = (state: RootState) => {
  return state.user;
};

export const getUserId = (state: RootState) => {
  return state.user.id;
};

export const getVerificationStatus = (state: RootState) => {
  return getUserState(state).validation_status;
};

export const { setEmailConfig, setSuperAdmin, setGanCredentials, setUserId } = slice.actions;

export default slice.reducer;
