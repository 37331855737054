import "./drawer-styles.css";
import DrawerSection from "components/drawer/drawer-section";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { getUserState } from "redux/slices/user-slice";

const NavigationLinks = () => {
  const user = useAppSelector(getUserState);
  return (
    <>
      <div>
        <DrawerSection icon="home" label="Home" path={"/"} />
        <DrawerSection icon="mybets" label="My Bets" path="/mybets" />
        {
          /* <DrawerSection
          icon="leaderboard"
          label="Leaderboard"
          path="/leaderboard"
        />*/
          <DrawerSection
            icon="confirm"
            label="Confirm Results"
            path="/confirm-results"
          />
        }
        <DrawerSection icon="help" label="Help" path="/help" />
        <DrawerSection
          icon="settings"
          label="Settings"
          path="/notification-settings"
        />
        {user.super_admin && <DrawerSection icon={"wrench"} label={"Debug"} path={"/debug"} />}
      </div>
    </>
  );
};
export default NavigationLinks;
