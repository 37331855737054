import React, { useState } from "react";
import { Button, Col, Collapse, Container, Form, Row, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { getUserState } from "redux/slices/user-slice";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import store from "redux/store";

const getAllCookies = (): Record<string, string> => {
  const cookies = document.cookie.split("; ");
  const cookieObj: Record<string, string> = {};
  cookies.forEach((cookie) => {
    const [key, value] = cookie.split("=");
    cookieObj[key] = value;
  });
  return cookieObj;
};

const DebugPage: React.FC = () => {
  const history = useHistory();

  const [localStorageKey, setLocalStorageKey] = useState("");
  const [localStorageValue, setLocalStorageValue] = useState("");
  const [localStorageData, setLocalStorageData] = useState(
    Object.keys(localStorage).map((key) => ({
      key,
      value: localStorage.getItem(key),
    }))
  );

  const [reduxStateOpen, setReduxStateOpen] = useState(false);

  const user = useAppSelector(getUserState);
  const reduxState = store.getState();

  const handleSetLocalStorage = () => {
    if (localStorageKey && localStorageValue) {
      localStorage.setItem(localStorageKey, localStorageValue);
      updateLocalStorageData();
    }
  };

  const handleRemoveLocalStorage = (key: string) => {
    localStorage.removeItem(key);
    updateLocalStorageData();
  };

  const updateLocalStorageData = () => {
    setLocalStorageData(
      Object.keys(localStorage).map((key) => ({
        key,
        value: localStorage.getItem(key),
      }))
    );
  };

  const cookieData = getAllCookies();
  const commitHash = process.env.REACT_APP_COMMIT_HASH || "Not available";

  return (
    <Container
      fluid
      className="p-4"
      style={{
        backgroundColor: "white",
        maxWidth: "100vw",
        overflowX: "hidden",
        margin: "0 auto",
        width: "100%",
      }}
    >
      <h1 className="mt-4 mb-4">Debug Page</h1>

      <Button variant="secondary" onClick={() => history.push("/")} className="mb-4">
        Go Back
      </Button>

      <Row className="mb-4">
        <Col md={6}>
          <h2>Build Info</h2>
          <BlockyHeavyText>
            Commit Hash: {commitHash}
          </BlockyHeavyText>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6}>
          <h2>User Id</h2>
          <BlockyHeavyText>
            {user.id}
          </BlockyHeavyText>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={12}>
          <h2 onClick={() => setReduxStateOpen(!reduxStateOpen)} style={{ cursor: "pointer" }}>
            Redux State (Click to {reduxStateOpen ? "Collapse" : "Expand"})
          </h2>
          <Collapse in={reduxStateOpen}>
            <div style={{
              padding: "1em",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#f5f5f5",
              maxHeight: "400px",
              overflowY: "scroll",
              fontFamily: "monospace",
              whiteSpace: "pre-wrap"
            }}>
              <span style={{ color: "#7c4dff" }}>
                {JSON.stringify(reduxState, null, 2)}
              </span>
            </div>
          </Collapse>
        </Col>
      </Row>

      {/* Current Location Section */}
      <Row className="mb-4">
        <Col md={12}>
          <h2>Current Location</h2>
          <div style={{
            padding: "1em",
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "#f5f5f5",
            fontFamily: "monospace",
            whiteSpace: "pre-wrap"
          }}>
            {window.location.href}
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <h2>Manipulate Local Storage</h2>
          <Form>
            <Form.Group controlId="localStorageKey">
              <Form.Label>Key</Form.Label>
              <Form.Control
                type="text"
                value={localStorageKey}
                onChange={(e) => setLocalStorageKey(e.target.value)}
                placeholder="Enter local storage key"
              />
            </Form.Group>

            <Form.Group controlId="localStorageValue">
              <Form.Label>Value</Form.Label>
              <Form.Control
                type="text"
                value={localStorageValue}
                onChange={(e) => setLocalStorageValue(e.target.value)}
                placeholder="Enter local storage value"
              />
            </Form.Group>

            <Button variant="primary" className="mt-3" onClick={handleSetLocalStorage}>
              Set Local Storage
            </Button>
          </Form>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h2>Local Storage Data</h2>
          <Table striped bordered hover responsive>
            <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {localStorageData.map((item) => (
              <tr key={item.key}>
                <td>{item.key}</td>
                <td
                  style={{
                    maxWidth: "200px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <div style={{ overflowX: "auto" }}>{item.value}</div>
                </td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => handleRemoveLocalStorage(item.key)}
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h2>Cookies</h2>
          <Table striped bordered hover responsive>
            <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(cookieData).map(([key, value]) => (
              <tr key={key}>
                <td>{key}</td>
                <td
                  style={{
                    maxWidth: "200px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <div style={{ overflowX: "auto" }}>{value}</div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default DebugPage;
