import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import * as storage from "utils/local-storage";
import { HAS_SEEN_SPARKET_TUTORIAL } from "utils/constants";

interface TutorialState {
  isOpen: boolean;
}

const initialState: TutorialState = { isOpen: false };

const slice = createSlice({
  name: "sparketTutorial",
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = !state.isOpen;
    },
    close: (state) => {
      storage.local.setItem(HAS_SEEN_SPARKET_TUTORIAL, "true");
      state.isOpen = false;
    },
  },
});

export const { open, close } = slice.actions;

export const getSparketTutorialState = (state: RootState) => {
  return state.sparketTutorial.isOpen;
};

export default slice.reducer;
