import { Modal } from "react-bootstrap";
import LoadingSpinner from "components/loading-spinner.tsx";
import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";

interface LegalModalProps {
  show: boolean;
  hideModal: () => void;
  header: string;
  endPoint: string;
}

const LegalModal = ({ show, hideModal, header, endPoint }: LegalModalProps) => {
  const [loading, setLoading] = useState(true);
  const [body, setBody] = useState("");
  useEffect(() => {
    if (show) {
      fetch(endPoint)
        .then((res) => res.json())
        .then((data) => {
          setBody(data.doc as string);
        })
        .catch(() => {
          setBody("");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [body, endPoint, show]);

  return (
    <>
      <Modal show={show} onHide={hideModal} size="lg">
        <Modal.Header className={styles.legalModalHeader} closeButton>
          <Modal.Title>
            <BlockyBoldText>{header}</BlockyBoldText>
          </Modal.Title>
        </Modal.Header>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Modal.Body className={styles.legalModalBody}>
            <ReactMarkdown>{body}</ReactMarkdown>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};
export default LegalModal;
