import { PoolDetailsResponse } from "interfaces/pool-details-response";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { Link } from "react-router-dom";
import { useState } from "react";
import OutcomeHistoryTable from "./outcome-history-table";

interface Props {
  pool: PoolDetailsResponse;
}

const Outcome = ({ pool }: Props) => {
  const [showOutcomeHistory, setShowOutcomeHistory] = useState(false);

  if (!pool.results) {
    return <div></div>;
  }

  const {
    results: { description, result_changes },
  } = pool;

  return (
    <div>
      <BlockyHeavyText style={{ color: "white", marginTop: 10 }}>
        Outcome: {description}
      </BlockyHeavyText>

      {result_changes.length > 1 && (
        <BlockyMediumText style={{ color: "white", marginTop: 10 }}>
          Note: This outcome has been changed by an administrator.{" "}
          <Link
            to="#"
            onClick={() => setShowOutcomeHistory(!showOutcomeHistory)}
          >
            View Outcome History
          </Link>
        </BlockyMediumText>
      )}
      {showOutcomeHistory && <OutcomeHistoryTable pool={pool} />}
    </div>
  );
};

export default Outcome;
