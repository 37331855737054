import {
  CURRENT_GROUP_SLUG,
  PENN_GROUP_API_PREFIX,
  PENN_GROUP_SLUG,
  PENN_GROUP_SUBDOMAIN,
  STN_GROUP_API_PREFIX,
  STN_GROUP_SLUG,
  STN_GROUP_SUBDOMAIN
} from "./constants";

export const getGroupApiPrefix = () => {
  const groupSlug = localStorage.getItem(CURRENT_GROUP_SLUG);
  switch (groupSlug) {
    case STN_GROUP_SLUG:
      return STN_GROUP_API_PREFIX;
    case PENN_GROUP_SLUG:
      return PENN_GROUP_API_PREFIX;
    default:
      // fallback in case the local storage got wiped out on mobile
      if (window.location.hostname.includes(STN_GROUP_SUBDOMAIN)) {
        return STN_GROUP_API_PREFIX;
      } else if (window.location.hostname.includes(PENN_GROUP_SUBDOMAIN)) {
        return PENN_GROUP_API_PREFIX;
      } else {
        throw new Error(`unable to resolve api prefix for group: ${groupSlug}`);
      }
  }
};
export const getInitializeGamePath = () => `/gan/${getGroupApiPrefix()}/initialize-game`;
// bet list page
export const getMyBetsPath = () => `/gan/${getGroupApiPrefix()}/bets`;

// v2/groups
const GROUPS_LAMBDA_PREFIX = "/v2/groups";
export const getPoolDetailsPath = (groupId: string, poolId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}}/pools/pool/${poolId}`;

export const getGroupDetailsPath = (groupId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}`;

export const getGroupBySlugPath = (slug: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/slug/${slug}`;

export const getPoolsPath = (groupId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/pools`;

// export const getBetDetailsPath = (betId: string) => `/gan/bets/bet/${betId}`;

export const getContestsPath = (groupId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/contests`;

export const getSocialShareLinksPath = (poolID: string) =>
  `/v2/pools/pool/${poolID}/socialshare`;

export const getConfirmBetPath = (
  groupId: string,
  poolId: string,
  poolEntryId: string
) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/pools/pool/${poolId}/entries/entry/${poolEntryId}/bet`;

// export const getJoinGroupPath = (groupId: string) =>
//   `${GROUPS_LAMBDA_PREFIX}/join/${groupId}`;

// v2/pmSys
export const getConfirmedContestantPath = (poolId: string) => `/gan/${getGroupApiPrefix()}/confirmation/pool/${poolId}/selected`;

export const getConfirmContestantPath = () => `/gan/${getGroupApiPrefix()}/confirmation/record`;

export const getGanBalancePath = () => `/gan/${getGroupApiPrefix()}/balance`;

export const getEntryPath = (groupId: string, poolId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/pools/pool/${poolId}/entries/entry`;

// v2/wallet
const WALLET_LAMBDA_PREFIX = "/v2/wallet";
export const getWalletPath = (groupId: string) =>
  `${WALLET_LAMBDA_PREFIX}/user/group/${groupId}`;

// v2/transactions
const TRANSACTIONS_LAMBDA_PREFIX = "/v2/transactions";
export const getTransactionsForWalletPath = (walletId: string) =>
  `${TRANSACTIONS_LAMBDA_PREFIX}/wallet/${walletId}`;

export const getLeaderboardDataPath = () =>
  `${TRANSACTIONS_LAMBDA_PREFIX}/leader-board`;

// v2/users
const USERS_LAMBDA_PREFIX = "/v2/users";
export const getUserPath = () => `/gan/${getGroupApiPrefix()}/users/me`;

export const getUserPathNotifications = () => `/gan/${getGroupApiPrefix()}/users/me?notifications=1`;

// export const getUsersPersonalInformationPath = () =>
//   `${USERS_LAMBDA_PREFIX}/verification/personal-information`;

export const getUsersExternalIdPath = () =>
  `${USERS_LAMBDA_PREFIX}/external-id`;

export const getUsersExternalIdsPath = () =>
  `${USERS_LAMBDA_PREFIX}/external-ids`;

export const getSubmitAvaterPath = () =>
  `${USERS_LAMBDA_PREFIX}/profile/avatar`;

export const getValidatePotentialUsernamePath = () =>
  `${USERS_LAMBDA_PREFIX}/onboarding/username`;

export const getVerificationRequestPath = (userId: string) =>
  `${USERS_LAMBDA_PREFIX}/user/${userId}/verification/idscan/sms`;

export const getFailedLoginPath = () =>
  `${USERS_LAMBDA_PREFIX}/user/events/event/login-failed`;

// v2/notification-configurations
const CONFIGURABLE_NOTIFICACTIONS_LAMBDA_PREFIX =
  "/v2/notification-configurations";
export const getConfigurableNotificationsPath = () =>
  CONFIGURABLE_NOTIFICACTIONS_LAMBDA_PREFIX;

// v2/legal
const LEGAL_LAMBDA_PREFIX = "/v2/legal";
export const getPrivacyPolicyPath = () =>
  `${LEGAL_LAMBDA_PREFIX}/docs/privacy-policy/v1`;

export const getTermsOfServicePath = () =>
  `${LEGAL_LAMBDA_PREFIX}/docs/terms-of-service/v1`;

const EVENTS_LAMBDA_PREFIX = "/v2/events";
export const getEventsPath = () => EVENTS_LAMBDA_PREFIX;

// v2/limitations
const LIMITATIONS_LAMBDA_PREFIX = "/v2/limitations";
export const getSelfLimitationsPath = () => `${LIMITATIONS_LAMBDA_PREFIX}/self`;
export const getSelfExclusionPath = () =>
  `${LIMITATIONS_LAMBDA_PREFIX}/self/exclusion`;
