import { Entry } from "interfaces/entry";
import SingleSelectionOpenEntryItem
  from "components/pool-details/entries/single-selection-entry-list/single-selection-open-entry-item";
import SingleSelectionSettledEntryItem
  from "components/pool-details/entries/single-selection-entry-list/single-selection-settled-enrty-item";
import { POOL_STATUS } from "utils/constants";
import { ContestantResponse } from "interfaces/contestant-response";
import { ContestantId } from "interfaces/contestant-result-places";

interface SingleSelectionEntryListProps {
  entries: Entry[];
  contestants: ContestantResponse[];
  selectEntry: (entry: Entry | undefined) => void;
  winningContestantIds: ContestantId[];
  poolStatus: number;
  selectedEntry: Entry | undefined;
  setIsFetchingEntry: (isFetchingEntry: boolean) => void;
}

const getItem = (poolStatus: number) => {
  switch (poolStatus) {
    case POOL_STATUS.PENDING:
      return SingleSelectionSettledEntryItem;
    case POOL_STATUS.OPEN:
      return SingleSelectionOpenEntryItem;
    case POOL_STATUS.LIVE:
      return SingleSelectionSettledEntryItem;
    case POOL_STATUS.SETTLED:
      return SingleSelectionSettledEntryItem;
    default:
      return SingleSelectionSettledEntryItem;
  }
};

const moveWinnerEntryToTheTop = (entries: Entry[], winningEntryIds: string[]) => {
  entries.forEach((entry: Entry, index: number) => {
    if (winningEntryIds.includes(entry.contestant_id)) {
      entries.splice(index, 1);
      entries.unshift(entry);
    }
  });
  return entries;
};

const SingleSelectionEntryList = ({
                                    entries,
                                    contestants,
                                    selectEntry,
                                    winningContestantIds,
                                    poolStatus,
                                    selectedEntry,
                                    setIsFetchingEntry,
                                  }: SingleSelectionEntryListProps) => {
  const totalSeedDistribution = contestants.reduce((acc, item) => {
    return acc + item.seed_distribution;
  }, 0);

  const Item = getItem(poolStatus);

  const sortedEntries = winningContestantIds
    ? moveWinnerEntryToTheTop(entries, winningContestantIds)
    : entries;

  return (
    <>
      {sortedEntries.map((entry: Entry) => {
        const projectedWin =
          entry.seed_distribution / totalSeedDistribution || 0;
        const isSelected =
          !!selectedEntry &&
          entry.pool_entry_id === selectedEntry.pool_entry_id;
        return (
          <Item
            key={entry.pool_entry_id}
            selectEntry={selectEntry}
            entry={entry}
            winner={winningContestantIds.includes(entry.contestant_id)}
            isSelected={isSelected}
            projectedWin={projectedWin}
            setIsFetchingEntry={setIsFetchingEntry}
          />
        );
      })}
    </>
  );
};

export default SingleSelectionEntryList;
