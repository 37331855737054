interface Props {
  children: string | any;
  style?: object;
  customStyles?: object;
  className?: string;
  ref?: any;
}
const BlockyMediumText = (props: Props) => {
  return (
    <div
      {...props}
      style={{
        fontFamily: "Chakra Petch",
        fontWeight: 450,
        ...props.style,
        ...props.customStyles,
      }}
    >
      {props.children}
    </div>
  );
};

export default BlockyMediumText;
