import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import BlockyBoldText from "./custom-texts/blocky-bold-text";
import { WifiOff } from "react-bootstrap-icons";

const OFFLINE = "offline";
const ONLINE = "online";

const OfflineAlert = () => {
  const [offline, setOffline] = useState(false);

  useEffect(() => {
    window.addEventListener(OFFLINE, (event) => {
      setOffline(true);
    });

    window.addEventListener(ONLINE, (event) => {
      setOffline(false);
    });
  }, []);

  return (
    <Modal show={offline} backdrop="static" keyboard={false}>
      <Modal.Body style={{ height: "70vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <WifiOff height={100} width={100} />
          <BlockyBoldText>Internet connection lost.</BlockyBoldText>
          <BlockyBoldText>
            Please reconnect to continue wagering.
          </BlockyBoldText>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OfflineAlert;
