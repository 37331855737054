import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface DrawerState {
  isOpen: boolean;
}

const initialState: DrawerState = { isOpen: false };

const slice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    toggle: (state) => {
      state.isOpen = !state.isOpen;
    },
    close: (state) => {
      state.isOpen = false;
    },
  },
});

export const { toggle, close } = slice.actions;

export const getDrawerState = (state: RootState) => {
  return state.drawer.isOpen;
};

export default slice.reducer;
