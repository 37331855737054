import Dinero from "dinero.js";
import { GroupSlug } from "redux/slices/group-slice";

const getSymbolByProvider = (groupUri: GroupSlug) => {
  if (groupUri === "sparketsportsbook") {
    return "Coins";
  }
  return "";
};

export const formatAsCurrency = (amount: number, groupUri: GroupSlug) => {
  let currencySymbol = getSymbolByProvider(groupUri);

  return Dinero({ amount: Math.floor(amount * 100) })
    .toFormat()
    .replace("$", currencySymbol+" ");
};

export const formattedCurrencyToNumber = (value: string): number => {
  return parseFloat(value.replace(/[^0-9.]+/g, ""));
};
