import { Tab } from "react-bootstrap";
import { Entry } from "interfaces/entry";
import { PoolDetailsResponse } from "interfaces/pool-details-response";
import { useState, useCallback, useMemo, useEffect } from "react";
import BetForm from "./bet-form";
import { Bet } from "interfaces/bet";
import LoadingBetForm from "./loading-bet-form";
import { betTypeExacta, betTypeTrifecta, betTypeWin } from "interfaces/bet-type-name";
import useWallet from "../../../hooks/useWallet";
import BetTabs from "./entry-list-features/bet-tabs";
import BetEntry from "./entry-list-features/entry";

interface EntryListProps {
  pool: PoolDetailsResponse;
  setCurrentPoolType: any;
  onSuccessfulBetPlaced: (bet: Bet) => void;
}

const EntryList = ({
                     pool,
                     setCurrentPoolType,
                     onSuccessfulBetPlaced,
                   }: EntryListProps) => {
  const [selectedEntry, setSelectedEntry] = useState<Entry>();
  const [isFetchingEntry, setIsFetchingEntry] = useState(false);

  const wallet = useWallet();

  const orderedBetTypes = useMemo(() => {
    const betTypesOrder = [betTypeWin, betTypeExacta, betTypeTrifecta];
    const betTypes = pool.pool_types || [betTypeWin];
    return betTypesOrder.filter((type) => betTypes.includes(type));
  }, [pool.pool_types]);

  const handleTabSelect = (tab: string | null) => {
    setCurrentPoolType(tab);
    setSelectedEntry(undefined);
  };

  useEffect(() => {
    if (selectedEntry) {
      setIsFetchingEntry(false);
    }
  }, [selectedEntry]);


  const renderBetEntries = () => {
    return orderedBetTypes.map((type) => (
      <BetEntry
        key={type}
        type={type}
        pool={pool}
        setIsFetchingEntry={setIsFetchingEntry}
        selectedEntry={selectedEntry}
        setSelectedEntry={setSelectedEntry}
      />
    ));
  };

  return (
    <div>
      <div>
        <Tab.Container
          id="bet-type-tabs"
          defaultActiveKey={orderedBetTypes[0]}
          onSelect={handleTabSelect}
        >
          {orderedBetTypes.length > 1 && <BetTabs orderedBetTypes={orderedBetTypes} />}
          <Tab.Content style={{ position: "relative" }}>
            {renderBetEntries()}
            {selectedEntry && (
              <div style={{height: 163}}>
              </div>
            )}
          </Tab.Content>
        </Tab.Container>
      </div>
      {selectedEntry && (
        <div style={{ flexShrink: 0 }}>
          <BetForm
            entry={selectedEntry}
            pool={pool}
            onSuccessfulBetPlaced={onSuccessfulBetPlaced}
            wallet={wallet}
          />
        </div>
      )}
      {isFetchingEntry && <LoadingBetForm />}
    </div>
  );
};

export default EntryList;
