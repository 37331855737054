interface calculateOddsInput {
  poolTotal: number;
  entryTotal: number;
  betAmount: number;
  fee: number;
}
export const calculateOdds = ({
  poolTotal,
  entryTotal,
  betAmount,
  fee,
}: calculateOddsInput): number => {
  const commision_multiplier = 1 - fee;
  const poolTotalWithCurrentBet = poolTotal + betAmount;
  const poolTotalLessCommission =
    poolTotalWithCurrentBet * commision_multiplier;
  const entryTotalWithCurrentBet = entryTotal + betAmount;

  if (entryTotalWithCurrentBet === 0) {
    return commision_multiplier;
  }
  return poolTotalLessCommission / entryTotalWithCurrentBet;
};
