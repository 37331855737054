import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import styles from "../styles.module.css";

const HeaderRow = () => {
  return (
    <thead>
      <tr style={{ fontSize: 18 }}>
        <th className={styles.leaderboardTableHeader}>
          <BlockyHeavyText>Rank</BlockyHeavyText>
        </th>
        <th className={styles.leaderboardTableHeader}>
          <BlockyHeavyText>Username</BlockyHeavyText>
        </th>
        <th className={styles.leaderboardTableHeader}>
          <BlockyHeavyText>Winnings</BlockyHeavyText>
        </th>
      </tr>
    </thead>
  );
};

export default HeaderRow;
