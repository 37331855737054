import SingleSelectionOpenEntryItem from "components/pool-details/entries/single-selection-entry-list/single-selection-open-entry-item";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { Entry } from "interfaces/entry";
import { Dispatch, SetStateAction, useState } from "react";
import AreYouSureForm from "./are-you-sure-form";
import SingleSelectionLiveEntryItem from "./live-enrty-item";
import { getConfirmContestantPath } from "utils/backend-path-builders";
import { getUserState } from "redux/slices/user-slice";

interface Props {
  entries: Entry[];
  poolId: string;
  confirmedContestantId: string;
  setConfirmedContestantId: Dispatch<SetStateAction<string>>;
}

const Representer = ({
                       entries,
                       poolId,
                       setConfirmedContestantId,
                       confirmedContestantId,
                     }: Props) => {
  const [selectedContestantId, setSelectedContestantId] = useState("");
  const [loading, setLoading] = useState(false);
  const groupId = useAppSelector(getGroupState).id;
  const [areYouSureFormHeight, setAreYouSureFormHeight] = useState(70);
  const user = useAppSelector(getUserState);
  const group = useAppSelector(getGroupState);
  const totalSeedDistribution = entries.reduce((acc, item) => {
    return acc + item.seed_distribution;
  }, 0);

  const confirmResult = (entry: Entry) => {
    setLoading(true);
    const contestantId = entry.contestant_id;

    fetch(process.env.REACT_APP_BACKEND_URL + getConfirmContestantPath(), {
      method: "POST",
      headers: {
        "x-gan-user-id": user.playerId,
        "x-gan-login-token": user.loginToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        group_id: groupId,
        contestant_id: contestantId,
        pool_id: poolId,
        status: "selected",
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        setConfirmedContestantId(contestantId);
        setSelectedContestantId("");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {entries.map((entry) => {
        const isSelected = selectedContestantId === entry.contestant_id;
        const isConfirmed = confirmedContestantId === entry.contestant_id;
        const projectedWin =
            entry.seed_distribution / totalSeedDistribution || 0;

        return (
          <div key={entry.contestant_id} style={{ position: "relative" }}>
            {confirmedContestantId === "" ? (
              <SingleSelectionOpenEntryItem
                entry={entry}
                selectEntry={() => setSelectedContestantId(entry.contestant_id)}
                setHeight={setAreYouSureFormHeight}
                projectedWin={projectedWin}
                setIsFetchingEntry={(a: boolean)=>{}}
              />
            ) : (
              <SingleSelectionLiveEntryItem
                item={entry}
                confirmed={isConfirmed}
                hasActionButton={false}
                projectedWin={projectedWin}
              />
            )}
            {isSelected && (
              <AreYouSureForm
                isLoading={loading}
                onConfirm={confirmResult}
                entry={entry}
                height={areYouSureFormHeight}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default Representer;
