import { useAuth } from "hooks/auth";
import { Mixpanel } from "hooks/mixPanel";
import { toast } from "react-toastify";
import { sparketBlack } from "utils/constants";
import styles from "../styles.module.css";
import { ReactComponent as CopyLinkWhite } from "icons/copy-link-white.svg";
import Button from "react-bootstrap/Button";

interface Props {
  url: string;
}

const CopyPoolButton = ({ url }: Props) => {
  const auth = useAuth();

  return (
    <>
      <input
        style={{ display: "none" }}
        id="pool-share-link"
        value={url}
        readOnly
      ></input>
      <Button
        onClick={() => {
          Mixpanel.track("Clicked Share Pool link", {
            isSignedIn: auth.signedIn,
          });
          var tempInput = document.createElement("input");
          (tempInput as any).style =
            "position: absolute; left: -1000px; top: -1000px";
          tempInput.value = url;
          document.body.appendChild(tempInput);
          tempInput.select();
          document.execCommand("copy");
          document.body.removeChild(tempInput);
          toast.success("Pool link copied successfully");
        }}
        style={{
          height: 50,
          width: 50,
          backgroundColor: sparketBlack,
          border: "none",
        }}
      >
        <div className={styles.text}>
          <CopyLinkWhite style={{ height: 40, width: 40 }} />
        </div>
      </Button>
    </>
  );
};

export default CopyPoolButton;
